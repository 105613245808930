import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SideCompanyInfo from './SideCompanyInfo';
import MapResSearch from './MapResSearch';
import SerchFilter from './SerchFilter';

const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Y2UyM2EzNmExN2NjNTFmNDE2MDJhMyIsImlhdCI6MTY5MTc0Mjc0MSwiZXhwIjoxNjk0MzM0NzQxfQ.wlTfERtSY9vSgLzYp4YdrpigPID4_9nFlVqnWEs_0eY";

console.log(accessToken)
const config = {
    headers:{
        Authorization: `Bearer ${accessToken}`
    }
}

const SearchResSearch = () => {
  const [data,setdata] = useState([])
  const [check,setcheck] = useState("")
  const [name,setname] = useState("")
  const [contact,setcontact] = useState("")
  const [address,setaddress] = useState("")
  const [bool,setbool] = useState(false)
  useEffect(() => {
                axios.get("https://nodig-backend.onrender.com/companies", config)
                .then((res) => {
                  console.log(res.data)
                  setdata(res.data)
                }).catch((err) => {
                  // alert("Failed")
                })
              },[bool])
  const navigate = useNavigate();
  return (
    <div className="main-searchPage">
      
      <SerchFilter />
      
    <div className='main-searchResSearch'>
      {/*  */}
      {(bool) ? (<><SideCompanyInfo id={check} newname={name} /> <MapResSearch address = {address}/></>) : (<></>)}
      {data.map((item,index) => {
        return(
          
          <div className="search-frame-group" >
          <div className="search-frame-wrapper">
            <div className="search-frame-container">
              <div className="search-frame-div">
                <div className="search-frame-parent1">
                  <div className="search-untitled-design-1-1-parent">
                    <img
                      className="search-untitled-design-1-1"
                      alt=""
                      src="/search-untitled-design-1-1@2x.png"
                    />
                    <div className="search-company-name-parent" onMouseOver={() => {
                        // navigate('/companies')
                        setbool(true)
                        setcheck(item._id)
                        setname(item?.name)
                        setcontact(item?.contact)
                        setaddress(item?.address)
                      }}>
                      <div className="search-company-name" >{item?.name}</div>
                      <div className="search-location-2-parent">
                        <img
                          className="search-location-2-icon1"
                          alt=""
                          src="/search-location-21.svg"
                        />
                        <div className="search-location-each">
                        {item?.address}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search-frame-wrapper1">
                    <div className="search-parent">
                      <div className="search-div rating">5.0</div>
                      <div className="search-frame-parent2">
                        <div className="search-tile-icons-parent">
                          <img
                            className="search-tile-icons"
                            alt=""
                            src="/search-tile-icons.svg"
                          />
                          <img
                            className="search-tile-icons"
                            alt=""
                            src="/search-tile-icons.svg"
                          />
                          <img
                            className="search-tile-icons"
                            alt=""
                            src="/search-tile-icons1.svg"
                          />
                          <img
                            className="search-tile-icons"
                            alt=""
                            src="/search-tile-icons1.svg"
                          />
                        </div>
                        <div className="search-reviews">100 reviews</div>
                      </div>
                    </div>
                  </div>
                  <div className="search-verified-badge">
                    <img
                      className="search-verified-1"
                      alt=""
                      src="/search-verified--1@2x.png"
                    />
                  </div>
                </div>
                <div>
                  <hr style={{width:"44rem",borderTop:"0.1px solid #EAEBED",marginLeft:"1rem",marginTop:"-2rem"}}/>
                </div>
                <div className="search-frame-parent3">
                  <div className="search-frame-wrapper2">
                    <div className="search-frame-parent4">
                      <div className="search-phone-android-phone-mobile-de-parent">
                        <img
                          className="search-phone-android-phone-mobile-de-icon"
                          alt=""
                          src="/search-phoneandroidphonemobiledevicesmartphoneiphone.svg"
                        />
                        <div className="search-covered-areas">Covered Areas</div>
                      </div>
                      <div className="search-california-other-other" style={{marginLeft:"1rem"}}>
                        {item?.areas}
                      </div>
                    </div>
                  </div>
                  <div className="search-frame-wrapper3">
                    <div className="search-frame-parent4">
                      <div className="search-phone-android-phone-mobile-de-parent">
                        <img
                          className="search-wrench-crescent-tool-construc-icon"
                          alt=""
                          src="/search-wrenchcrescenttoolconstructiontoolswrenchsettingeditadjust.svg"
                        />
                        <div className="search-covered-areas">Services</div>
                      </div>
                      <div className="search-california-other-other">
                        {item?.services}
                      </div>
                    </div>
                  </div>
                  <div className="search-frame-wrapper3">
                    <div className="search-frame-parent6">
                      <div className="search-bag-dollar-bag-payment-cash-m-parent">
                        <img
                          className="search-bag-dollar-bag-payment-cash-m-icon"
                          alt=""
                          src="/search-bagdollarbagpaymentcashmoneyfinance.svg"
                        />
                        <div className="search-covered-areas">Estimates</div>
                      </div>
                      <div className="search-california-other-other">{item?.estimate}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={(bool== true) ? ("search-frame-parent7-hover") : ("search-frame-parent7")} style={{marginTop:"0.4rem"}}>
            <div className="search-tile-icons-group group-border">
              <img className="search-tile-icons4" alt="" src="/search-tile-icons2.svg" />
              <div className="search-div" style={{color:"#48B1A3"}} onClick={() => {
                console.log(item?.contact)
              }}>Call</div>
            </div>
            <div className="search-tile-icons-container group-border">
              <img className="search-tile-icons4" alt="" src="/search-tile-icons3.svg" />
              <div className="search-div" style={{color:"#517FCC"}}>Appointment</div>
            </div>
            <div className="search-union-parent group-border">
              <img className="search-union-icon" alt="" src="/search-union.svg" />
              <div className="search-div" style={{color:"#48B1A3"}}>Direct message</div>
            </div>
            <div className="search-tile-icons-parent1 group-border">
              <img className="search-tile-icons4" alt="" src="/search-tile-icons4.svg" />
              <a href={item?.webURL} target="_blank" style={{textDecoration:"none"}}><div className="search-div" style={{color:"#48B1A3"}}> Go to website </div></a>
            </div>
          </div>
        </div>


        )
      })}
          
    </div>
    </div>
  )
}

export default SearchResSearch