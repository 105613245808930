import React from 'react'

const Herocentre = () => {
  return (
    <div>
        <div className="hero-centre">
          <div className="grow-your-business-parent">
            <div className="grow-your-business-container">
              <span>{`Grow `}</span>
              <span className="nodigcom">your</span>
              <span> business</span>
            </div>
            <div className="nodigcom-is-the" style={{marginLeft:"-0.5rem"}}>{`NoDig.com is the world’s largest and most comprehensive Sewer & Drain directory.`}</div>
            <div className="increase-your-visibility">
              Increase your visibility and get more leads.
            </div>
            <div className="verified-badge5">
              <img className="new-2-icon" alt="" src="/verified--12@2x.png" />
            </div>
          </div>
          <div className="buttons10">
            <div className="button-base11" >
            <div className="text8">Text Goes Here</div>
            </div>
          </div>
          <div className="tile-parent">
            <div className="tile">
              <div className="tile-icons-parent6">
                <img className="tile-icons10" alt="" src="/tile-icons4.svg" />
                <div className="title">Increase Visibility</div>
              </div>
              <div className="text24">
                Showcase your business to a vast customer base actively
                searching for trenchless repair services.
              </div>
            </div>
            <div className="tile">
              <div className="tile-icons-parent6">
                <img className="tile-icons10" alt="" src="/tile-icons5.svg" />
                <div className="title">Targeted Leads</div>
              </div>
              <div className="text24">
                Receive qualified leads from customers specifically interested
                in trenchless repair, ensuring higher conversion rates.
              </div>
            </div>
            <div className="tile">
              <div className="tile-icons-parent6">
                <img className="tile-icons10" alt="" src="/tile-icons6.svg" />
                <div className="title">
                  <p className="join-forces-with-nodigcom">{`Build Trust & `}</p>
                  <p className="join-forces-with-nodigcom">Credibility</p>
                </div>
              </div>
              <div className="text24">
                Utilize our platform to showcase your expertise, qualifications,
                and customer reviews, instilling confidence in potential
                customers.
              </div>
            </div>
            <div className="tile">
              <div className="tile-icons-parent6">
                <img className="tile-icons10" alt="" src="/tile-icons7.svg" />
                <div className="title">
                  <p className="join-forces-with-nodigcom">{`Streamlined `}</p>
                  <p className="join-forces-with-nodigcom">Communication</p>
                </div>
              </div>
              <div className="text24">
                Efficiently communicate with customers, receive quote requests,
                and schedule appointments, all within the platform.
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Herocentre