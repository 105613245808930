import React from 'react'

const Benefits = () => {
  return (
    <div>
        <div className="benefits">
        <div className="more-than-just-leads-parent">
          <div className="more-than-just-container">
            <span>More</span>
            <span className="than-just-leads"> than just leads</span>
          </div>
          <div className="instance-parent">
            <div className="tile-icons-group">
              <img className="tile-icons1" alt="" src="/tile-icons1.svg" />
              <div className="title">More qualified/unique leads</div>
            </div>
            <div className="tile-icons-group">
              <img className="tile-icons1" alt="" src="/tile-icons1.svg" />
              <div className="title">Become NoDig Verified</div>
            </div>
            <div className="tile-icons-group">
              <img className="tile-icons1" alt="" src="/tile-icons1.svg" />
              <div className="title">RealTime 360 Software Suite</div>
            </div>
            <div className="tile-icons-group">
              <img className="tile-icons1" alt="" src="/tile-icons1.svg" />
              <div className="title">Company profile page</div>
            </div>
          </div>
          <div className="buttons">
            <div className="button-base2" >
            <div className="text8">Get Started</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits