import React from 'react'

const InfoCompanies = () => {
  return (
    <div>
        <div className="companies-info-parent">
          <div className="companies-info">Info:</div>
          <div className="companies-frame-parent">
            <div className="companies-covered-areas-parent">
              <div className="companies-covered-areas">{`Covered areas: `}</div>
              <div className="companies-lorem-ipsum-dolor">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud `}</div>
            </div>
            <div className="companies-covered-areas-parent">
              <div className="companies-covered-areas">Services:</div>
              <div className="companies-lorem-ipsum-dolor">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud `}</div>
            </div>
            <div className="companies-covered-areas-parent">
              <div className="companies-covered-areas">Estimates:</div>
              <div className="companies-lorem-ipsum-dolor">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud `}</div>
            </div>
            <div className="companies-covered-areas-parent">
              <div className="companies-covered-areas">Special offers:</div>
              <div className="companies-lorem-ipsum-dolor">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud `}</div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default InfoCompanies