import React, { useState } from 'react'
import getReview from '../../services/review.service'
import { useEffect } from 'react'

const MembersSay = () => {
//   const [dataa, setDataa] = useState([]);

//   useEffect(() => {
//     console.log("Before calling getReview");
//     getReview()
//       .then(response => {
//         console.log("Inserting in state", response.data.data);
//         var d = response.data.data
//         setDataa(d); 
//         console.log("Data in State", dataa);
//       })
//       .catch(error => {
//         console.error("Error fetching data:", error);
//       });
// }, []);

const [dataa, setDataa] = useState([]);

useEffect(() => {
  console.log("Before calling getReview");
  getReview()
    .then(response => {
      console.log("Inserting in Members Say state", response.data);
      var d = response.data;
      setDataa(d);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
}, []);

useEffect(() => {
  console.log("Data in Members Say State", dataa);
}, [dataa]);

// const mappedData = dataa.map(item => ({
//   id: item.id,
//   attributes: item.attributes
// }));
const mappedData = dataa.map(item => ({
  id: item._id,
  attributes: {
    rating: item.rating,
    reviewDescription: item.reviewDescription,
  }
}));  

// console.log("Mapped Data:", mappedData[0].attributes);



  return (
    <div>
        <div className="what-our-members-say-parent">
        <div className="what-our-members">What our members say</div>
        <div className="tile-icons-parent10">
          <img className="tile-icons14" alt="" src="/tile-icons8.svg" />
          <div className="frame-parent3">
          {mappedData.map((data, index) => {
            if(index<3)
            return(
        <div className="frame-parent4" key={index}>
          <div className="tile-icons-group">
            <img
              className="frame-child2"
              alt=""
              src="/ellipse-873@2x.png"
            />
            <div className="john-smiths-parent">
              <div className="john-smiths">John Smiths</div>
              <div className="managing-director-abc-container">
                <p className="join-forces-with-nodigcom">
                  Managing Director
                </p>
                <p className="abc-company">@ABC Company</p>
              </div>
            </div>
          </div>
          <div className="tile-icons-parent11">
            {Array.from({ length: data.attributes.rating }, (_, index) => (
              <img
                key={index}
                className="tile-icons15"
                alt=""
                src="/tile-icons9.svg"
              />
            ))}
          </div>

          <div>
            <div className="im-very-satisfied">
              {data.attributes.reviewDescription}
            </div>
          </div>
        </div>
)})}
        </div>
          <img className="tile-icons14" alt="" src="/tile-icons11.svg" />
          </div>
        <div className="tile-icons-parent11">
          <div className="ellipse-div" />
          <div className="ellipse-div" />
          <div className="frame-child6" />
        </div>
      
    </div>
    </div>
  )
}

export default MembersSay