import React from 'react'

const HowItWorks = () => {
  return (
    <div>
        <div className="how-it-works1">
            <div className="frame-parent1">
              <div className="how-it-works-parent">
                <div className="how-it-works-container">
                  <span>{`How it `}</span>
                  <span className="works">works</span>
                </div>
                <div className="ellipse-group">
                  <img
                    className="frame-child"
                    alt=""
                    src="/ellipse-87@2x.png"
                  />
                  <div className="create-your-business">
                    Create your business profile
                  </div>
                  <div className="sign-up-and">
                    Sign up and create a compelling profile that highlights your
                    business, expertise, certifications, and previous work.
                  </div>
                </div>
                <div className="ellipse-group">
                  <img
                    className="frame-child"
                    alt=""
                    src="/ellipse-871@2x.png"
                  />
                  <div className="create-your-business">
                    Receive quote requests
                  </div>
                  <div className="sign-up-and">
                    Customers can submit quote requests directly to you,
                    providing essential project details for accurate pricing.
                  </div>
                </div>
              </div>
              <div className="how-it-works-parent">
                <div className="ellipse-group">
                  <img
                    className="frame-child"
                    alt=""
                    src="/ellipse-872@2x.png"
                  />
                  <div className="create-your-business">
                    Engage with customers
                  </div>
                  <div className="sign-up-and">
                    Communicate with potential customers, answer questions, and
                    provide quotes promptly to secure new projects.
                  </div>
                </div>
                <div className="ellipse-group">
                  <img
                    className="frame-child"
                    alt=""
                    src="/ellipse-88@2x.png"
                  />
                  <div className="create-your-business">
                    Schedule appointments
                  </div>
                  <div className="sign-up-and">
                    Easily schedule appointments, allowing customers to book
                    your services at their convenience.
                  </div>
                </div>
                <div className="ellipse-group">
                  <img
                    className="frame-child"
                    alt=""
                    src="/ellipse-881@2x.png"
                  />
                  <div className="create-your-business">Grow your business</div>
                  <div className="sign-up-and">
                    Take advantage of our platform's tools and features to
                    expand your customer base, increase revenue, and drive
                    business growth.
                  </div>
                </div>
              </div>
            </div>
            <div className="buttons1">
              <div className="button-base8" >
              <div className="text8">Text Goes Here</div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default HowItWorks