import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

const HeaderAbout = () => {
    const navigate = useNavigate()
  return (
    <div>
        <div className="about-header">
        <div className="about-logo1">
          <img className="about-new-1-icon" alt="" src="/about-new-11@2x.png" />
        </div>
        <div className="about-header1">
          <div className="about-main-navigation">
            <b className="about-home" onClick={() => {
                navigate('/')
              }}>Home</b>
            <b className="about-about">About</b>
            <b className="about-search" onClick={() => {
                navigate('/search')
              }}>Search</b>
          </div>
          <div className="about-header2">
            <div className="about-buttons4">
              <div className="about-buttons5" onClick={() => {
                navigate('/home')
              }}>
                <div className="about-text3">Become a verified installer</div>
              </div>
            </div>
            {/* <div className="about-buttons6">
              <div className="about-text4">Installer log in</div>
            </div> */}

            <div className="about-buttons6">
                <Link to="/login" className="about-text4">
                    Login
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderAbout