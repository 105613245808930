import React, { useState } from 'react'
import axios from 'axios'
import constants from '../config/contants'
const API_URL = constants.API_URL
let authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Y2UyM2EzNmExN2NjNTFmNDE2MDJhMyIsImlhdCI6MTY5MjI4NDkyMywiZXhwIjoxNjk0ODc2OTIzfQ.gfimwbazZHCm1weYw8wziG72APlURK9dLFKJ3RzP9R0"

export const getFAQ = () => {
    let url = API_URL + "/faqs"
    return (
      axios.get(url, {
          headers: {
              Authorization: `Bearer ${authToken}`
          }
      })
    //   .then(response => {
    //       console.log(response.data);
    //   })
    //   .catch(error => {
    //       console.error(error)
    //   })
    )
}