import React from 'react'

const WhatIsNodig = () => {
  return (
    <div>
        <div className="about-what-is-nodig-verified">
        <img
          className="about-georgia-de-lotz-ebb8fe-nztm-un-icon3"
          alt=""
          src="/about-georgiadelotzebb8fenztmunsplash-3@2x.png"
        />
        <img
          className="about-button-play-button-television-icon"
          alt=""
          src="/about-buttonplaybuttontelevisionbuttonsmoviesplaytvvideocontrols.svg"
        />
        <div className="about-h22">
          <span>{`What is `}</span>
          <span className="about-nodigcom">NoDig Verified</span>
        </div>
      </div>
    </div>
  )
}

export default WhatIsNodig