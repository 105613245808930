import React, { useState } from 'react'
import axios from 'axios'
import constants from '../config/contants'
const API_URL = constants.API_URL
let authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Y2UyM2EzNmExN2NjNTFmNDE2MDJhMyIsImlhdCI6MTY5MjI4NDkyMywiZXhwIjoxNjk0ODc2OTIzfQ.gfimwbazZHCm1weYw8wziG72APlURK9dLFKJ3RzP9R0"

const getReview = async () => {
console.log("Get Review Funcion");
  let url = API_URL + "/reviews"
  return (
    axios.get(url, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    // .then(response => {
    //     console.log("Reivews:" + response);
    //     console.log(response.data.data);
    //     // setReviews(response.data.data)       
             
    // })
    // .catch(error => {
    //     console.error(error)
    // })
  )
}

export default getReview