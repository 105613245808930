import React from 'react'

const Footer = () => {
  return (
    <div>
        <div className="group-parent2">
        <div className="open-mail-parent">
          <img className="open-mail-icon" alt="" src="/open-mail.svg" />
          <img className="location-2-icon" alt="" src="/location-2.svg" />
          <img className="phone-icon" alt="" src="/phone.svg" />
          <div className="lorem-ipsum">Lorem ipsum</div>
          <div className="lorem-ipsum1">Lorem ipsum</div>
          <div className="lorem-ipsum2">Lorem ipsum</div>
          <div className="lorem-ipsum3">Lorem ipsum</div>
          <div className="lorem-ipsum4">Lorem ipsum</div>
          <div className="lorem-ipsum5">Lorem ipsum</div>
          <div className="lorem-ipsum6">Lorem ipsum</div>
          <div className="lorem-ipsum7">Lorem ipsum</div>
          <div className="lorem-ipsum8">Lorem ipsum</div>
          <div className="lorem-ipsum9">Lorem ipsum</div>
          <div className="lorem-ipsum10">Lorem ipsum</div>
          <div className="lorem-ipsum11">Lorem ipsum</div>
          <div className="lorem-ipsum12">Lorem ipsum</div>
          <div className="lorem-ipsum13">Lorem ipsum</div>
          <div className="lorem-ipsum14">Lorem ipsum</div>
          <div className="lorem-ipsum15">Lorem ipsum</div>
        </div>
        <div className="logo1">
          <img className="new-2-icon1" alt="" src="/new-1@2x.png" />
        </div>
      </div>
    </div>
  )
}

export default Footer