import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, HashRouter } from "react-router-dom";
// import '@coreui/coreui/dist/css/coreui.min.css';
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import About from "./components/about/about";
import Search from "./components/search/search";
import Companies from "./components/companies/companies";
import { Suspense } from "react";
// import './scss/style.scss'
// import './scss/style.scss'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  function PrivateRoute({ element }) {
    return localStorage.getItem('jwt') ? element : <Navigate to="/login" replace={true} />;
  }  
  return (
    // <div className="container">
      // <Router>
      <HashRouter>
        <Suspense fallback={loading}>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<About />} />
          <Route path="/companies/:companyId" element={<Companies />} />
          <Route path="/search" element={<Search />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/kartikey" element={<DefaultLayout />} /> */}
          {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
          {/* <Route
            path="*"
            element={
              localStorage.getItem('jwt') ? (
                <DefaultLayout />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          /> */}
          <Route path="*" name="Home" element={<PrivateRoute element={<DefaultLayout />} />} />
        </Routes>
        </Suspense>
      {/* // </Router> */}
      </HashRouter>
    // </div>
  );
};

export default App;
