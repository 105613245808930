import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
const Header = () => {

  
  return (
    <div>
        <div className="home-header">
          <div className="home-logo">
            <img className="new-2-icon" alt="" src="/new-2@2x.png" />
          </div>
          <div className="main-navigation1">
            <div className="main-navigation-wrapper">
              <div className="main-navigation2">
                <div className="home1">Home</div>
                <div className="page-11">Page 1</div>
                <div className="page-21">Page 2</div>
              </div>
            </div>
            <div className="buttons-parent">
              <div className="buttons7">
                <div className="buttons8">
                  <div className="button-base8" >
                  <HashLink to="#with-hash-fragment"> <div className="text8"><span style={{textDecoration:'none',color:'white'}}>Sign Up</span></div></HashLink>
                  </div>
                </div>
              </div>
              <div className="buttons9">
              <div className="button-base10" >
                <div className="text22">
                  {/* <a href="http://localhost:3000" target='_blank'>Log In</a> */}
                  <Link to='/login' style={{textDecoration:"none",color:"black"}}>Log In</Link>
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Header