import React,{useEffect,useState} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
const HeroCompanies = () => {

  const [company,setCompany]=useState({name:''});
  const {companyId}=useParams();
  useEffect(()=>
  {
      const fetchPost=async()=>
      {
          const response= await axios.get(`https://nodig-backend.onrender.com/companies/${companyId}`);
          setCompany(response.data);
      }
      fetchPost();
  },[]);
  console.log(company);
  return (
    <div>
        <div className="companies-hero">
        <div className="companies-hero-child" />
        <img className="companies-hero-item" alt="" src="/companies-ellipse-1113@2x.png" />
        <div className="companies-group-div">
          <div className="companies-div7">5.0</div>
          <div className="companies-frame-wrapper">
            <div className="companies-tile-icons-parent2">
              <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
              <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
              <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
              <img className="companies-tile-icons" alt="" src="/companies-tile-icons7.svg" />
            </div>
          </div>
          <div className="companies-reviews1">100 reviews</div>
        </div>
        <div className="companies-company-name">{company.name}</div>
        <div className="companies-verified-badge">
          <img className="companies-verified-1" alt="" src="/companies-verified--1@2x.png" />
        </div>
        <div className="search-search-bar">
          <div className="search-magnifying-glass-glass-search-parent">
            <img
              className="search-magnifying-glass-glass-search-icon"
              alt=""
              src="/search-magnifyingglassglasssearchmagnifying.svg"
            />
            <div className="search-div">
            <input type="text" className='search-input-zip-code' placeholder='How can we help ?' />
            </div>
          </div>
          <div className="search-location-compass-1-arrow-comp-parent">
            <img
              className="search-location-compass-1-arrow-comp-icon"
              alt=""
              src="/search-locationcompass1arrowcompasslocationgpsmapmapspoint.svg"
            />
            <div className="search-div">
            <input type="text" className='search-input-zip-code' placeholder='Enter your ZIP code' />
            </div>
          </div>
          <div className="search-buttons5">
            <div className="search-buttons6">
              <div className="search-text3">SEARCH</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroCompanies