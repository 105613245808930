import React, { useRef,useEffect} from 'react';
// import { HashLink } from 'react-router-hash-link';
import Header from './Header';
import Herocentre from './Herocentre';
import './Home.css'
import PropTypes from 'prop-types';
import MembersSay from './MembersSay';
import HeroDown from './HeroDown';
import HowItWorks from './HowItWorks';
import Benefits from './Benefits';
import MembershipLevels from './MembershipLevels';
import RegistrationForm from './RegistrationForm';
import Footer from './Footer';
import Faqs from './Faqs';
// 


const Home = () => {
  
  const onClick=(e)=>
  {
    
  }
  
return (
  <div>
  

<div className="benefits-parent">
      <Benefits />
      <MembershipLevels />
      <RegistrationForm />
      <div className="how-it-works" />
      <div className="hero">
        <div className="intro">
          <HeroDown />
          <HowItWorks />
        </div>
        <Header onClick={onClick} />
        <Herocentre />
      </div>
      <MembersSay />
      <Faqs />
      <Footer />
    </div>
  </div>
)
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
