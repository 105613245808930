import React from 'react';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import './companies.css'
import HeaderAbout from '../about/HeaderAbout';
import FooterCompanies from './FooterCompanies';
import InfoCompanies from './InfoCompanies';
import ReviewsCompanies from './ReviewsCompanies';
import LeftCompanies from './LeftCompanies';
import HeroCompanies from './HeroCompanies';

const Companies = () => {
  
  const [company,setCompany]=useState({name:'',address: "",
  contact: "",
  webURL: "",
  areas: "",
  services: "",
  estimate:""});
  const {companyId}=useParams();
  useEffect(()=>
  {
      const fetchPost=async()=>
      {
          const response= await axios.get(`https://nodig-backend.onrender.com/companies/${companyId}`);
          setCompany(response.data);
      }
      fetchPost();
  },[]);
  console.log(company);

  return(
  <div>
    <div className="companies-profile-parent">
      <div className="companies-profile">
        <div className="companies-profile-child">
        <div className="companies-profile-item">
        <div className="companies-rectangle-parent">
          <img className="companies-frame-child" alt="" src="/companies-rectangle-50@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-51@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-52@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-50@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-52@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-52@2x.png" />
        </div>
        <div className="companies-rectangle-group">
          <img className="companies-frame-child" alt="" src="/companies-rectangle-50@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-51@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-52@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-50@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-52@2x.png" />
          <img className="companies-frame-child" alt="" src="/companies-rectangle-52@2x.png" />
        </div>
        </div>
        </div>
        <InfoCompanies />
        <ReviewsCompanies />
        <LeftCompanies phone={company.contact} website={company.webURL} address={company.address} area={company.areas} />
        <img className="companies-image-5-icon" alt="" src="/companies-image-5@2x.png" />
        <div className="companies-gallery" style={{marginLeft:"-3rem"}}>Gallery</div>
        <div className="companies-profile-child1" />
        <div className="companies-frame-parent4">
          <div className="companies-tile-icons-group">
            <img className="companies-tile-icons13" alt="" src="/companies-tile-icons4.svg" />
            <div className="companies-covered-areas">Call</div>
          </div>
          <div className="companies-tile-icons-container">
            <img className="companies-tile-icons13" alt="" src="/companies-tile-icons5.svg" />
            <div className="companies-covered-areas">Appointment</div>
          </div>
          <div className="companies-tile-icons-group">
            <img className="companies-union-icon" alt="" src="/companies-union.svg" />
            <div className="companies-covered-areas" style={{width:"11rem"}}>Direct message</div>
          </div>
          <div className="companies-tile-icons-group">
            <img className="companies-tile-icons13" alt="" src="/companies-tile-icons6.svg" />
            <div className="companies-covered-areas" style={{width:"11rem",textAlign:"center"}}>{company.webURL}</div>
          </div>
        </div>
        <FooterCompanies />
      </div>
      <HeroCompanies />
      <HeaderAbout />
    </div>
  </div>
  )
  };

Companies.propTypes = {};

Companies.defaultProps = {};

export default Companies;
