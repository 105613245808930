import React from 'react'

const SerchFilter = () => {
  return (
    <div>
      
        <div className='main-fliter' style={{marginTop:"3rem"}}>
          <div>
        <b className="search-listings1" style={{marginTop:"28.5rem",marginLeft:"-25.5rem"}} > Listings</b>
        </div>
        <div className="search-frame-parent" style={{marginTop:"28.2rem",marginLeft:"-2.6rem"}}>
          <div className="search-group-wrapper">
            <div className="search-vector-parent">
              <img className="search-group-child" alt="" src="/search-vector-9.svg" style={{marginLeft:"0.5rem"}} />
              <div className="search-sort">Sort</div>
            </div>
          </div>
          <div className="search-group-container">
            <div className="search-vector-parent">
              <img className="search-group-child" alt="" src="/search-vector-9.svg" />
              <div className="search-sort">Filter</div>
            </div>
          </div>
          </div>
        </div>
      </div>
  )
}

export default SerchFilter
