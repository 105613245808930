import React from 'react'

const HeroDown = () => {
  return (
    <div>
        <div className="frame-container">
            <div className="group-wrapper">
              <div className="ellipse-parent">
                <div className="group-item" />
                <img
                  className="favpng-plumber-plumbing-hvac-d-icon"
                  alt=""
                  src="/favpng-plumberplumbinghvacdraincentralheating-2@2x.png"
                />
              </div>
            </div>
            <div className="frame-wrapper">
              <div className="join-forces-with-nodigcom-th-parent">
                <div className="join-forces-with-container">
                  <p className="join-forces-with-nodigcom">
                    <span>{`Join forces with `}</span>
                    <span className="nodigcom">Nodig.com,</span>
                  </p>
                  <p className="join-forces-with-nodigcom">
                    the leading platform dedicated to connecting trenchless
                    repair businesses with customers who appreciate your
                    unwavering commitment to excellence.
                  </p>
                </div>
                <div className="by-joining-our-container">
                  <p className="join-forces-with-nodigcom">
                    By joining our platform, you'll unlock invaluable
                    opportunities to cultivate a remarkable reputation and reap
                    the rewards of a consistent demand for your exceptional
                    services.
                  </p>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default HeroDown