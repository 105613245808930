import React from 'react'

const HowItWorksAbout = () => {
  return (
    <div>
        <div className="about-nodig-verified1">
          <div style={{marginLeft:"-10rem"}}>
        <img className="about-nodig-verified-child" alt="" src="/about-rectangle-49.svg" />
        <div className="about-verified-badge1">
          <img className="about-new-1-icon" alt="" src="/about-verified--11@2x.png" />
        </div>
        <div className="about-nodig-verified11">
          <div className="about-how-it-works-container">
            <span style={{color:"white"}}>{`How it `}</span>
            <span className="about-works">{`works `}</span>
          </div>
          <div className="about-how-it-works1">
            <div className="about-how-it-works2">
              <img
                className="about-how-it-works2-child"
                alt=""
                src="/about-group-2607.svg"
              />
              <div className="about-search-for-nodig-verified-inst-parent">
                <div className="about-title">
                  Search for nodig verified installers in your area
                </div>
                <div className="about-just-enter-what">
                  Just enter what services you need and your zip code
                </div>
              </div>
            </div>
            <div className="about-how-it-works2">
              <img
                className="about-how-it-works2-child"
                alt=""
                src="/about-group-2607.svg"
              />
              <div className="about-search-for-nodig-verified-inst-parent">
                <div className="about-title">
                  Read reviews and shortlist installers
                </div>
                <div className="about-just-enter-what1">
                  We will list all the NoDig verified installers in your area
                  and you can browse the company profile listings of as many
                  suppliers as you need.
                </div>
              </div>
            </div>
            <div className="about-how-it-works2">
              <img
                className="about-how-it-works2-child"
                alt=""
                src="/about-group-2607.svg"
              />
              <div className="about-search-for-nodig-verified-inst-parent">
                <div className="about-title">Contact your chosen installer</div>
                <div className="about-just-enter-what1">
                  Contact your chosen installer and agree the details of your
                  project
                </div>
              </div>
            </div>
            <div className="about-how-it-works2">
              <img
                className="about-how-it-works2-child"
                alt=""
                src="/about-group-2607.svg"
              />
              <div className="about-search-for-nodig-verified-inst-parent">
                <div className="about-how-can-we">Job done!</div>
                <div className="about-just-enter-what3">
                  Contact your chosen installer and agree the details of your
                  project
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksAbout