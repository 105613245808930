import React from 'react'

const WelcomeAbout = () => {
  return (
    <div>
        <div className="about-welcome">
        <div className="about-welcome1">
          <div className="about-h2">
            <span>{`Welcome to `}</span>
            <span className="about-nodigcom">NoDig.com</span>
          </div>
          <div className="about-body">
            <p className="about-trenchless">
              Welcome to Nodig.com, the no1 online destination for homeowners in
              the United States seeking verified trenchless repair services. Our
              platform connects you with a curated selection of trusted and
              Nodig Verified contractors specializing in the latest trenchless
              technologies.
            </p>
            <p className="about-trenchless">&nbsp;</p>
            <p className="about-trenchless">
              Say goodbye to the hassle of traditional repair methods and
              discover a more efficient, cost-effective, and
              environmentally-friendly approach to fixing your underground
              infrastructure.
            </p>
            <p className="about-trenchless">&nbsp;</p>
          </div>
          <div className="about-buttons2">
            <div className="about-text1">About Us</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeAbout