import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import '@coreui/coreui/dist/css/coreui.min.css';
import {
  CButton,
  CCard,
  CCardBody, 
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import axios from 'axios'; 
import { useState } from 'react';
import { useEffect } from 'react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // localStorage.setItem('jwt', "")

  const handleLogin = () => {
    axios.post('https://nodig-backend.onrender.com/auth/local', {
      identifier: username,
      password: password,
    })
    .then(response => {
      console.log(response.data.jwt);
      localStorage.setItem('jwt', response.data.jwt)
      window.location.reload();
    })
    .catch(error => {
      console.log(error.meesage)
    });
  };

  const navigate = useNavigate()
  useEffect(() => {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      navigate('/dashboard');
    }
  }, [])
  
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Username"
                        autoComplete="username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </CInputGroup>
                    <CRow>
                    <CCol xs={6}>
                      <CButton color="primary" className="px-4" onClick={handleLogin}>
                        Login
                      </CButton>
                    </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
