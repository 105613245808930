import React from 'react'

const ReviewsCompanies = () => {
  return (
    <div>
        <div className="companies-profile-inner">
          <div className="companies-frame-group">
            <div className="companies-reviews-parent">
              <div className="companies-info">Reviews:</div>
              <div className="companies-frame-container">
                <div className="companies-group-wrapper">
                  <div className="companies-vector-parent">
                    <img className="companies-group-child" style={{marginLeft:"0.6rem"}} alt="" src="/companies-vector-9.svg" />
                    <div className="companies-sort">Sort</div>
                  </div>
                </div>
                <div className="companies-group-container">
                  <div className="companies-vector-parent">
                    <img className="companies-group-child" alt="" src="/companies-vector-9.svg" />
                    <div className="companies-sort">Filter</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="companies-frame-div">
              <div className="companies-parent">
                <div className="companies-div">5.0</div>
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons.svg" />
              </div>
              <div className="companies-div" style={{color: "var(--dark-purple, #517FCC)"}}>John Doe, New York</div>
              <div className="companies-div1" style={{color: "var(--dark-purple, #517FCC)"}}>04/03/2023</div>
              <div className="companies-im-very-satisfied">
                “I’m very satisfied. Highly recommended. They’re the best. I
                can’t recommend enough. Worked very well for me. I’m very
                satisfied. Highly recommended. They’re the best. I can’t
                recommend enough. Worked very well for me.”
              </div>
            </div>
            <div className="companies-frame-div">
              <div className="companies-parent">
                <div className="companies-div">5.0</div>
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons1.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons1.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons1.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons1.svg" />
              </div>
              <div className="companies-div" style={{color: "var(--dark-purple, #517FCC)"}}>John Doe, California</div>
              <div className="companies-div" style={{color: "var(--dark-purple, #517FCC)"}}>05/03/2023</div>
              <div className="companies-im-very-satisfied">
                “I’m very satisfied. Highly recommended. They’re the best. I
                can’t recommend enough. Worked very well for me. I’m very
                satisfied. Highly recommended. They’re the best. I can’t
                recommend enough. Worked very well for me.”
              </div>
            </div>
            <div className="companies-frame-div">
              <div className="companies-parent">
                <div className="companies-div">5.0</div>
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
                <img className="companies-tile-icons" alt="" src="/companies-tile-icons2.svg" />
              </div>
              <div className="companies-div" style={{color: "var(--dark-purple, #517FCC)"}}>John Doe, Arizona</div>
              <div className="companies-div" style={{color: "var(--dark-purple, #517FCC)"}}>04/02/2023</div>
              <div className="companies-im-very-satisfied">
                “I’m very satisfied. Highly recommended. They’re the best. I
                can’t recommend enough. Worked very well for me. I’m very
                satisfied. Highly recommended. They’re the best. I can’t
                recommend enough. Worked very well for me.”
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ReviewsCompanies