import React from 'react'

const FooterSearch = () => {
  return (
    <div>
        <div className="search-footer">
          <div className="search-open-mail-parent">
            <img className="search-open-mail-icon" alt="" src="/search-open-mail.svg" />
            <img className="search-location-2-icon" alt="" src="/search-location-2.svg" />
            <img className="search-phone-icon" alt="" src="/search-phone.svg" />
            <div className="search-lorem-ipsum" style={{fontSize:"25px"}}>Lorem ipsum</div>
            <div className="search-lorem-ipsum1" style={{fontSize:"25px"}}>Lorem ipsum</div>
            <div className="search-lorem-ipsum2">Lorem ipsum</div>
            <div className="search-lorem-ipsum3">Lorem ipsum</div>
            <div className="search-lorem-ipsum4">Lorem ipsum</div>
            <div className="search-lorem-ipsum5">Lorem ipsum</div>
            <div className="search-lorem-ipsum6" style={{fontSize:"25px"}}>Lorem ipsum</div>
            <div className="search-lorem-ipsum7">Lorem ipsum</div>
            <div className="search-lorem-ipsum8">Lorem ipsum</div>
            <div className="search-lorem-ipsum9">Lorem ipsum</div>
            <div className="search-lorem-ipsum10" style={{fontSize:"25px"}}>Lorem ipsum</div>
            <div className="search-lorem-ipsum11">Lorem ipsum</div>
            <div className="search-lorem-ipsum12">Lorem ipsum</div>
            <div className="search-lorem-ipsum13">Lorem ipsum</div>
            <div className="search-lorem-ipsum14">Lorem ipsum</div>
            <div className="search-lorem-ipsum15">Lorem ipsum</div>
          </div>
          <div className="search-logo">
            <img className="search-new-1-icon" alt="" src="/search-new-1@2x.png" />
          </div>
        </div>
    </div>
  )
}

export default FooterSearch