import React, { useEffect, useState } from 'react'
import FooterAbout from './FooterAbout'
import axios from 'axios'

const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Y2UyM2EzNmExN2NjNTFmNDE2MDJhMyIsImlhdCI6MTY5MTc0Mjc0MSwiZXhwIjoxNjk0MzM0NzQxfQ.wlTfERtSY9vSgLzYp4YdrpigPID4_9nFlVqnWEs_0eY";

console.log(accessToken)
const config = {
    headers:{
        Authorization: `Bearer ${accessToken}`
    }
}
const ArticlesAbout = () => {
  const [data,setdata] = useState([])
  useEffect(() => {
                axios.get("https://nodig-backend.onrender.com/articles", config)
                .then((res) => {
                  console.log(res.data)
                  setdata(res.data)
                }).catch((err) => {
                  // alert("Failed")
                })
              },[])

  return (
    <div>
        <div className="about-articles">
        <div className="about-h21">
          <span>{`Our latest `}</span>
          <span className="about-nodigcom">Articles</span>
        </div>
        <div className="about-articles11">
          
         {data.map((item,index) => {
          if(index<3)
          return(

          <div className="about-articles2">
          <img
            className="about-georgia-de-lotz-ebb8fe-nztm-un-icon"
            alt=""
            src="/about-georgiadelotzebb8fenztmunsplash-2@2x.png"
          />
          <div className="about-articles3">
            <div className="about-title">{item?.title}</div>
            <div className="about-body1">
            {item?.description}
            </div>
          </div>
        </div> 
        )})}
          {/* <div className="about-articles2">
            <img
              className="about-georgia-de-lotz-ebb8fe-nztm-un-icon"
              alt=""
              src="/about-georgiadelotzebb8fenztmunsplash-21@2x.png"
            />
            <div className="about-articles3">
              <div className="about-title">Lorem ipsum</div>
              <div className="about-body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
            </div>
          </div>
          <div className="about-articles2">
            <img
              className="about-georgia-de-lotz-ebb8fe-nztm-un-icon"
              alt=""
              src="/about-georgiadelotzebb8fenztmunsplash-22@2x.png"
            />
            <div className="about-articles3">
              <div className="about-title">Lorem ipsum</div>
              <div className="about-body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
            </div>
          </div> */}
        </div>
        <FooterAbout />
        <div className="about-buttons3">
          <div className="about-text1">Go to all articles</div>
        </div>
      </div>
    </div>
  )
}

export default ArticlesAbout