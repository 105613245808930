import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { getFAQ } from '../../services/faq.service'
import Faq from "react-faq-component";

var name = "bnn"

const data = {
    
    
    rows: [
        {
            title: "How much does it cost?",
            content: "It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business. (Link to membership listings page)",
        },
        {
            title: "How do I become a Nodig Verified installer?",
            content:
                "Once you have registered and claimed your listing, you will be asked to submit all relevant material to our registration team. We will confirm within 2 working days if you registration has been successful and if it hasn’t our dedicated account managers will work with your business to fix this.",
        },
        {
            title: "I am new to trenchless repair- is Nodig right for me?",
            content: "There is no minimum trading history required to list your services on our website, however, to become a no dig verified installer you will be required to show at least 2 years of successful trading history or completed at least XX jobs via our website.",
        },
        {
            title: "How do reviews work?",
            content: "It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business.",
        },
        {
          title: "How much does it cost?",
          content: "It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business.",
      },
    ],
};

const styles = {
    bgColor: '#F5F6F6',
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContentColor: 'black',
    // rowContentColor: 'grey',
    // arrowColor: "red",
    rowTitleTextSize: '32px',
    rowContentTextSize: '17px',
   

    
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

const Faqs = () => {
  const [dataa, setDataa] = useState([]);

  useEffect(() => {
    console.log("Before calling getReview");
    getFAQ()
      .then(response => {
        console.log("Inserting in state", response.data);
        var d = response.data;
        setDataa(d);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Data in faq Level State", dataa);
  }, [dataa]);

  const mappedData = dataa.map(item => ({
    id: item._id,
    attributes: {
      question: item.question,
      answer: item.answer,
    }
  }));  
  const [sectionStates, setSectionStates] = useState(Array(mappedData.length).fill(false));
  const [ans1,setans1] = useState(false)
  
  // const [ans2,setans2] = useState(false)
  // const [ans3,setans3] = useState(false)
  // const [ans4,setans4] = useState(false)
  // const [ans5,setans5] = useState(false)
  return (
    <div>
        <div className="faqs">
        <div className="faqs-parent">
          <div className="what-our-members">FAQs</div>
          <div className="group-parent1">
            {/* <div className="group-div">
              <div className="frame-parent7">
                <div className="group-frame">
                  <div className="how-much-does-it-cost-wrapper">
                    <div className="how-much-does-container">
                      <p className="join-forces-with-nodigcom">
                        How much does it cost?
                      </p>
                    </div>
                  </div>
                </div>
                {(ans1 === false) ? (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans1(true)
                }} />) : (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans1(false)
                }} />)}
              </div>
              <div className="group-inner" />
             { (ans1 === true) ? (<div className="it-is-free-container">
                <p className="join-forces-with-nodigcom">{`It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business. `}</p>
              </div>) : (<></>)}
            </div> */}
            {/* {mappedData.map((data, index) => (
            <div className="frame-parent8">
              <div className="frame-parent7">
                <div className="group-frame">
                  <div className="how-do-i-become-a-nodig-verifi-wrapper">
                    <div className="how-do-i-container">
                      <p className="join-forces-with-nodigcom">
                      {data.attributes.question}
                      </p>
                    </div>
                  </div>
                </div>
                {(ans1 === false) ? (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans1(true)
                }} />) : (<img className="tile-icons14" alt="" src="/tile-icons13.svg" onClick={() => {
                    setans1(false)
                }} />)}
              </div>
              <div className="group-inner" />
              { (ans1 === true) ? (<div className="it-is-free-container">
                <p className="join-forces-with-nodigcom">{data.attributes.answer}</p>
              </div>) : (<></>)}
            </div>
            ))} */}
            {/* {mappedData.map((data, index) => {
              if(index < 5){
              return(
              
              
              <div className="frame-parent8" key={index}>
                <div className="frame-parent7">
                  <div className="group-frame">
                    <div className="how-do-i-become-a-nodig-verifi-wrapper">
                      <div className="how-do-i-container">
                        <p className="join-forces-with-nodigcom ">
                         <span>{data.attributes.question}</span> 
                        </p>
                      </div>
                    </div>
                  </div>
                  <img
                    className="tile-icons14"
                    alt=""
                    src={sectionStates[index] ? "/tile-icons12.svg" : "/tile-icons13.svg"}
                    onClick={() => {
                      const newSectionStates = [...sectionStates];
                      newSectionStates[index] = !newSectionStates[index];
                      setSectionStates(newSectionStates);
                    }}
                  />
                </div>
                <div className="group-inner" />
                {sectionStates[index] && (
                  <div className="it-is-free-container">
                    <p className="join-forces-with-nodigcom"> <span className='span'>{data.attributes.answer}</span></p>
                  </div>
                )}
              </div>)}
})} */}

            {/* <div className="frame-parent8">
              <div className="frame-parent7">
                <div className="group-frame">
                  <div className="how-do-i-become-a-nodig-verifi-wrapper">
                    <div className="how-do-i-container">
                      <p className="join-forces-with-nodigcom">
                        How do I become a Nodig Verified installer?
                      </p>
                    </div>
                  </div>
                </div>
                {(ans2 === false) ? (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans2(true)
                }} />) : (<img className="tile-icons14" alt="" src="/tile-icons13.svg" onClick={() => {
                    setans2(false)
                }} />)}
              </div>
              <div className="group-inner" />
              { (ans2 === true) ? (<div className="it-is-free-container">
                <p className="join-forces-with-nodigcom">{`It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business. `}</p>
              </div>) : (<></>)}
            </div>
            <div className="frame-parent8">
              <div className="frame-parent7">
                <div className="group-frame">
                  <div className="i-am-new-to-trenchless-repair-wrapper">
                    <div className="how-do-i-container">
                      <p className="join-forces-with-nodigcom">
                     I am new to trenchless repair- is Nodig right for me?
                      </p>
                    </div>
                  </div>
                </div>
                {(ans3 === false) ? (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans3(true)
                }} />) : (<img className="tile-icons14" alt="" src="/tile-icons13.svg" onClick={() => {
                    setans3(false)
                }} />)}
              </div>
              <div className="group-inner" />
              { (ans3 === true) ? (<div className="it-is-free-container">
                <p className="join-forces-with-nodigcom">{`It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business. `}</p>
              </div>) : (<></>)}
            </div>
            <div className="frame-parent8">
              <div className="frame-parent7">
                <div className="group-frame">
                  <div className="i-am-new-to-trenchless-repair-wrapper">
                    <div className="how-do-i-container">
                      <p className="join-forces-with-nodigcom">
                        How do reviews work?
                      </p>
                      <p className="join-forces-with-nodigcom">&nbsp;</p>
                    </div>
                  </div>
                </div>
                {(ans4 === false) ? (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans4(true)
                }} />) : (<img className="tile-icons14" alt="" src="/tile-icons13.svg" onClick={() => {
                    setans4(false)
                }} />)}
              </div>
              <div className="group-inner" />
              { (ans4 === true) ? (<div className="it-is-free-container">
                <p className="join-forces-with-nodigcom">{`It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business. `}</p>
              </div>) : (<></>)}
            </div>
            <div className="frame-parent8">
              <div className="frame-parent7">
                <div className="group-frame">
                  <div className="i-am-new-to-trenchless-repair-wrapper">
                    <div className="how-do-i-container">
                      <p className="join-forces-with-nodigcom">
                        I already get a lot of leads - why should I still join?
                      </p>
                    </div>
                  </div>
                </div>
                {(ans5 === false) ? (<img className="tile-icons14" alt="" src="/tile-icons12.svg" onClick={() => {
                    setans5(true)
                }} />) : (<img className="tile-icons14" alt="" src="/tile-icons13.svg" onClick={() => {
                    setans5(false)
                }} />)}
              </div>
              <div className="group-inner" />
              { (ans5 === true) ? (<div className="it-is-free-container">
                <p className="join-forces-with-nodigcom">{`It is free to claim a listing on Nodig.com. We have various packages with additional benefits to further scale your business. `}</p>
              </div>) : (<></>)}
            </div> */}

            <Faq
                data={data}
                styles={styles}
                config={config}
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs