import React from 'react'
import HeaderAbout from '../about/HeaderAbout'

const HeroSearch = () => {
  return (
    <div>
      <div className="search-hero" style={{marginTop:"6.75rem"}}>
        {/* <div className="search-hero-child" />
        <div className="search-top-companies">Top Companies</div>
        <div className="search-near-location">near Location</div>
        <div className="search-verified-badge3">
          <img className="search-verified-13" alt="" src="/search-verified--11@2x.png" />
        </div> */}

       

        <div className="search-search-bar">
          <div className="search-magnifying-glass-glass-search-parent">
            <img
              className="search-magnifying-glass-glass-search-icon"
              alt=""
              src="/search-magnifyingglassglasssearchmagnifying.svg"
            />
            <div className="search-div">
              <input type="text" className='search-input-zip-code' placeholder='How can we help ?' />
            </div>
          </div>
          <div className="search-location-compass-1-arrow-comp-parent">
            <img
              className="search-location-compass-1-arrow-comp-icon"
              alt=""
              src="/search-locationcompass1arrowcompasslocationgpsmapmapspoint.svg"
            />
            <div className="search-div">
              <input type="text" className='search-input-zip-code' placeholder='Enter your ZIP code' />
            </div>
          </div>
          <div className="search-buttons5">
            <div className="search-buttons6">
              <div className="search-text3">SEARCH</div>
            </div>
          </div>
        </div>
      </div>
      
     <div className="hero-down">
      <div className="left-hero-down">
        <p className='p1-left-hero-down'>Top Companies</p>
        <p className='p2-left-hero-down'>Near Location</p>

      </div>
      <div className="right-hero-down">
      <img  alt="" className="search-verified-13" src="/search-verified--11@2x.png" />
      </div>
     </div>
     
    </div>
  )
}

export default HeroSearch