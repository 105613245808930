import React from 'react';
import PropTypes from 'prop-types';
import './search.css'
import HeaderAbout from '../about/HeaderAbout';
import FooterSearch from './FooterSearch';
import SearchResSearch from './SearchResSearch';
import SideCompanyInfo from './SideCompanyInfo';
import HeroSearch from './HeroSearch';
import MapResSearch from './MapResSearch';
import SerchFilter from './SerchFilter';

const Search = () => {
  return(
  <div>
    <HeaderAbout />
    <HeroSearch />
    {/* <SerchFilter /> */}
    <SearchResSearch />
    <FooterSearch />
  </div>
  )};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;