import React from 'react'

const FooterAbout = () => {
  return (
    <div>
        <div className="about-footer">
          <div className="about-open-mail-parent">
            <img className="about-open-mail-icon" alt="" src="/about-open-mail.svg" />
            <img className="about-location-2-icon" alt="" src="/about-location-2.svg" />
            <img className="about-phone-icon" alt="" src="/about-phone.svg" />
            <div className="about-lorem-ipsum">Lorem ipsum</div>
            <div className="about-lorem-ipsum1">Lorem ipsum</div>
            <div className="about-lorem-ipsum2">Lorem ipsum</div>
            <div className="about-lorem-ipsum3">Lorem ipsum</div>
            <div className="about-lorem-ipsum4">Lorem ipsum</div>
            <div className="about-lorem-ipsum5">Lorem ipsum</div>
            <div className="about-lorem-ipsum6">Lorem ipsum</div>
            <div className="about-lorem-ipsum7">Lorem ipsum</div>
            <div className="about-lorem-ipsum8">Lorem ipsum</div>
            <div className="about-lorem-ipsum9">Lorem ipsum</div>
            <div className="about-lorem-ipsum10">Lorem ipsum</div>
            <div className="about-lorem-ipsum11">Lorem ipsum</div>
            <div className="about-lorem-ipsum12">Lorem ipsum</div>
            <div className="about-lorem-ipsum13">Lorem ipsum</div>
            <div className="about-lorem-ipsum14">Lorem ipsum</div>
            <div className="about-lorem-ipsum15">Lorem ipsum</div>
          </div>
          <div className="about-logo">
            <img className="about-new-1-icon" alt="" src="/about-new-1@2x.png" />
          </div>
        </div>
    </div>
  )
}

export default FooterAbout