import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom';
import HeroCompanies from '../companies/HeroCompanies';
const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Y2UyM2EzNmExN2NjNTFmNDE2MDJhMyIsImlhdCI6MTY5MTc0Mjc0MSwiZXhwIjoxNjk0MzM0NzQxfQ.wlTfERtSY9vSgLzYp4YdrpigPID4_9nFlVqnWEs_0eY";

console.log(accessToken)
const config = {
    headers:{
        Authorization: `Bearer ${accessToken}`
    }
}

const SideCompanyInfo = ({newname,id}) => {
  const navigate=useNavigate();
  const name = newname
  const uid = id
  // alert(name)
  const [data,setdata] = useState([])
  
  useEffect(() => {
    axios.get("https://nodig-backend.onrender.com/companies/" + uid, config)
    .then((res) => {
      // console.log(res.data)
      setdata(res.data)
      
    }).catch((err) => {
      // alert("Failed")
    })
  },[uid])
  
  // alert(newid)
  console.log(data)
  
  return (
    <div>
        <div className="search-frame-parent25" key={data._id}>
          <div className="search-frame-wrapper17">
            <div className="search-another-company-wrapper">
              <b className="search-company-name-side">{data.name}</b>
            </div>
          </div>
          <div className="search-buttons">
            <div className="search-buttons1">
              <div className="search-button-base">
              <div className="search-text" onClick={() => {
                        navigate(`/companies/${data._id}`)
                        
                      }}>View Profile</div>
              </div>
            </div>
          </div>
          <div className="search-frame-parent26">
            <div className="search-phone-android-phone-mobile-de-parent1">
              <img
                className="search-phone-android-phone-mobile-de-icon3"
                alt=""
                src="/search-phoneandroidphonemobiledevicesmartphoneiphone1.svg"
              />
              <div className="search-infoemailcom">{data.contact}</div>
            </div>
            <div className="search-open-mail-group">
              <img className="search-location-2-icon1" alt="" src="/search-open-mail1.svg" />
              <div className="search-infoemailcom">info@gmail.com</div>
            </div>
            <div className="search-open-mail-group">
              <img
                className="search-magnifying-glass-glass-search-icon"
                alt=""
                src="/search-tile-icons11.svg"
              />
              <div className="search-infoemailcom">{data.webURL}</div>
            </div>
            <div className="search-open-mail-group">
              <img className="search-location-2-icon1" alt="" src="/search-location-23.svg" />
              <div className="search-infoemailcom">
                <p className="search-city-state">{data.address}</p>
                <p className="search-city-state">City, State</p>
              </div>
            </div>
          </div>
          <div className="search-frame-wrapper18">
            <div className="search-frame-parent27">
              <div className="search-parent1">
                <div className="search-div4">5.0</div>
                <img className="search-tile-icons" alt="" src="/search-tile-icons12.svg" />
                <img className="search-tile-icons" alt="" src="/search-tile-icons12.svg" />
                <img className="search-tile-icons" alt="" src="/search-tile-icons12.svg" />
                <img className="search-tile-icons" alt="" src="/search-tile-icons12.svg" />
              </div>
              <div className="search-john-doe-parent">
                <div className="search-john-doe">John Doe</div>
                <div className="search-location">Location</div>
                <div className="search-mmddyyyy">mm/dd/yyyy</div>
              </div>
            </div>
          </div>
          <div className="search-im-very-satisfied">
            “I’m very satisfied. Highly recommended. They’re the best. I can’t
            recommend enough. Worked very well for me. I’m very satisfied.
            Highly recommended. They’re the best. I can’t recommend enough.
            Worked very well for me.”
          </div>
        </div>
    </div>
  )
}

export default SideCompanyInfo