import React, { useState } from 'react'
// import { getMembership } from '../../services/getMembership.service';
import { useEffect } from 'react'
import Levels from './Levels';
import data from './membership.json'
const MembershipLevels = () => {
  const [dataa, setDataa] = useState([data]);

  // useEffect(() => {
  //   console.log("Before calling getReview");
  //   getMembership()
  //     .then(response => {
  //       console.log("Inserting in state", response.data);
  //       var d = response.data;
  //       setDataa(d);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   console.log("Data in Memebership Level State", dataa);
  // }, [dataa]);


  // const mappedData = dataa.map(item => ({
  //   id: item.id,
  //   attributes: item.attributes
  // }));
  // const mappedData = dataa.map(item => ({
  //   id: item._id,
  //   attributes: {
  //     name: item.name,
  //     description: item.description,
  //   }
  // }));  
  console.log(data);
  // console.log(dataa);
  
  return (
    <div>
        <div className="membership-levels">
        <div className="what-our-members">Membership levels</div>
        <div className="frame-parent">
        {data.map((item) => (
         <Levels name={item.name} description={item.description} color={item.color} textColor={item.textColor} />
          ))}
          {/* <div className="tile-icons-parent3">
            <img className="tile-icons5" alt="" src="/tile-icons2.svg" />
            <div className="verified-badge">
              <img className="new-2-icon" alt="" src="/verified--1@2x.png" />
            </div>
            <div className="bronze">Bronze</div>
            <div className="contact-form-tracking">
              Full use of NoDig verified badge
            </div>
            <div className="contact-form-tracking">
              Dedicated Contractor support
            </div>
            <div className="live-chat-tracking">Live Chat Tracking</div>
            <div className="contact-form-tracking">Contact Form Tracking</div>
            <div className="contact-form-tracking">Call Tracking</div>
            <div className="contact-form-tracking">Auto Estimates</div>
            <div className="buttons2">
              <div className="button-base2" >
              <div className="text8">Learn More</div>
              </div>
            </div>
          </div> */}
          {/* <div className="tile-icons-parent4">
            <img className="tile-icons5" alt="" src="/tile-icons2.svg" />
            <div className="verified-badge">
              <img className="new-2-icon" alt="" src="/verified--1@2x.png" />
            </div>
            <div className="bronze">Silver</div>
            <div className="contact-form-tracking">
              Full use of NoDig verified badge
            </div>
            <div className="contact-form-tracking">
              Dedicated Contractor support
            </div>
            <div className="live-chat-tracking">Live Chat Tracking</div>
            <div className="contact-form-tracking">Contact Form Tracking</div>
            <div className="contact-form-tracking">Call Tracking</div>
            <div className="contact-form-tracking">Auto Estimates</div>
            <div className="contact-form-tracking">Auto Bookings</div>
            <div className="contact-form-tracking">Google Review Software</div>
            <div className="buttons2">
              <div className="button-base2" >
              <div className="text8">Learn More</div>
              </div>
            </div>
          </div> */}
          {/* <div className="tile-icons-parent5">
            <img className="tile-icons5" alt="" src="/tile-icons2.svg" />
            <div className="verified-badge">
              <img className="new-2-icon" alt="" src="/verified--1@2x.png" />
            </div>
            <div className="bronze">Gold</div>
            <div className="contact-form-tracking">
              Full use of NoDig verified badge
            </div>
            <div className="contact-form-tracking">
              Dedicated Contractor support
            </div>
            <div className="live-chat-tracking">Live Chat Tracking</div>
            <div className="contact-form-tracking">Contact Form Tracking</div>
            <div className="contact-form-tracking">Call Tracking</div>
            <div className="contact-form-tracking">Auto Estimates</div>
            <div className="contact-form-tracking">Auto Bookings</div>
            <div className="contact-form-tracking">Google Review Software</div>
            <div className="contact-form-tracking">Social Media Software</div>
            <div className="buttons2">
              <div className="button-base2" >
              <div className="text8">Learn More</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default MembershipLevels