import React from 'react'

const LeftCompanies = ({phone,website,address,area}) => {
  return (
    <div>
        <div className="companies-certifications-parent">
          <div className="companies-covered-areas" style={{color: "var(--dark-purple, #517FCC)"}}>Certifications</div>
          <div className="companies-repair-10-cleaning-container">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed `}</div>
        </div>
        <div className="companies-completed-jobs-parent">
          <div className="companies-covered-areas" style={{color: "var(--dark-purple, #517FCC)"}}>Completed jobs:</div>
          <div className="companies-repair-10-cleaning-container">
            <p className="companies-repair">12 repair</p>
            <p className="companies-repair">10 cleaning</p>
            <p className="companies-repair">15 other</p>
          </div>
        </div>
        <div className="companies-buttons">
          <div className="companies-buttons1">
            <div className="companies-button-base">
            <div className="companies-text">Read Reviews</div>
            </div>
          </div>
        </div>
        <div className="companies-buttons2">
          <div className="companies-buttons1">
            <div className="companies-button-base1">
            <div className="companies-text">Read Reviews</div>
            </div>
          </div>
        </div>
        <div className="companies-frame-parent3">
          <div className="companies-phone-android-phone-mobile-de-parent">
            <img
              className="companies-phone-android-phone-mobile-de-icon"
              alt=""
              src="/companies-phoneandroidphonemobiledevicesmartphoneiphone.svg"
            />
            <div className="companies-infoemailcom">{phone}</div>
          </div>
          <div className="companies-open-mail-parent">
            <img className="companies-open-mail-icon" alt="" src="/companies-open-mail.svg" />
            <div className="companies-infoemailcom">info@email.com</div>
          </div>
          <div className="companies-open-mail-parent">
            <img
              className="companies-magnifying-glass-glass-search-icon"
              alt=""
              src="/companies-tile-icons3.svg"
            />
            <div className="companies-infoemailcom">{website}</div>
          </div>
          <div className="companies-open-mail-parent">
            <img className="companies-open-mail-icon" alt="" src="/companies-location-2.svg" />
            <div className="companies-infoemailcom">
              <p className="companies-repair">{area}</p>
              <p className="companies-repair">{address}</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default LeftCompanies