import React from 'react'

const FooterCompanies = () => {
  return (
    <div>
        <div className="companies-footer">
          <div className="companies-open-mail-group">
            <img className="companies-open-mail-icon1" alt="" src="/companies-open-mail1.svg" />
            <img className="companies-location-2-icon1" alt="" src="/companies-location-21.svg" />
            <img className="companies-phone-icon" alt="" src="/companies-phone.svg" />
            <div className="companies-lorem-ipsum">Lorem ipsum</div>
            <div className="companies-lorem-ipsum1">Lorem ipsum</div>
            <div className="companies-lorem-ipsum2">Lorem ipsum</div>
            <div className="companies-lorem-ipsum3">Lorem ipsum</div>
            <div className="companies-lorem-ipsum4">Lorem ipsum</div>
            <div className="companies-lorem-ipsum5">Lorem ipsum</div>
            <div className="companies-lorem-ipsum6">Lorem ipsum</div>
            <div className="companies-lorem-ipsum7">Lorem ipsum</div>
            <div className="companies-lorem-ipsum8">Lorem ipsum</div>
            <div className="companies-lorem-ipsum9">Lorem ipsum</div>
            <div className="companies-lorem-ipsum10">Lorem ipsum</div>
            <div className="companies-lorem-ipsum11">Lorem ipsum</div>
            <div className="companies-lorem-ipsum12">Lorem ipsum</div>
            <div className="companies-lorem-ipsum13">Lorem ipsum</div>
            <div className="companies-lorem-ipsum14">Lorem ipsum</div>
            <div className="companies-lorem-ipsum15">Lorem ipsum</div>
          </div>
          <div className="companies-logo">
            <img className="companies-new-1-icon" alt="" src="/companies-new-1@2x.png" />
          </div>
        </div>
    </div>
  )
}

export default FooterCompanies