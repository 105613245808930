import React from 'react';
import './about.css'
import PropTypes from 'prop-types';
import HeaderAbout from './HeaderAbout';
import HowItWorksAbout from './HowItWorksAbout';
import WhatIsNodig from './WhatIsNodig';
import FooterAbout from './FooterAbout';
import ArticlesAbout from './ArticlesAbout';
import WelcomeAbout from './WelcomeAbout';
import HeroAbout from './HeroAbout';


const About = () => {

  return(
 <div>
    <div className="about-hero-parent">
      <HeroAbout />
      <WelcomeAbout />
      <ArticlesAbout />
      <WhatIsNodig />
      <HowItWorksAbout />
      
      <HeaderAbout />
    </div>
 

 </div>
)};

About.propTypes = {};

About.defaultProps = {};

export default About;
